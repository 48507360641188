<template>
  <div class="container">
    <!-- 轮播图 -->
    <div class="banner">
      <div class="content">
        <el-carousel trigger="click" height="800px">
          <el-carousel-item v-for="(item, index) in banner_imgs" :key="index">
            <a :href="item.link" target="_blank">
              <img v-lazy="net + item.pic" alt="" />
            </a>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <!-- 公告 -->
    <div class="notice">
      <div class="notice-content">
        <div class="notice-left">
          <div class="notice-title">
            <img src="../assets/img/notice.png" alt="" />
            <span>最新公告</span>
          </div>
          <div class="notice-text">
            <el-carousel height="40px" direction="vertical">
              <el-carousel-item v-for="(item, index) in notices" :key="index">
                <span
                  class="medium"
                  @click="
                    $router.push({
                      path: '/noticeDetail',
                      query: { id: item.id },
                    })
                  "
                  >{{ item.title }}</span
                >
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <router-link to="/allNotice" class="more">
          <span>查看更多</span>
          <img src="../assets/img/next.png" alt="" />
        </router-link>
      </div>
    </div>
    <!-- 产品介绍 -->
    <div class="intro-section">
      <img src="../assets/img/bg1.png" class="bg1" alt="" />
      <div class="content">
        <!-- 入伙 -->
        <div class="join">
          <div class="join-title">
            <div class="line"></div>
            <div class="text">
              <span>0门槛入伙</span>
              轻松入伙无套路，收益所见即所得
            </div>
          </div>
          <div class="join-prods">
            <div class="prod-item" v-for="(item, index) in list" :key="index">
              <div class="item-header">
                <div class="header-left">
                  <img src="../assets/img/touzi.png" alt="" />
                  <span
                    style="cursor: pointer"
                    @click="
                      $router.push({
                        path: '/collection',
                        query: { id: item.id },
                      })
                    "
                    >{{ item.title }}</span
                  >
                </div>
                <div class="header-right">{{ item.operate_days }}天</div>
              </div>
              <div class="item-content">
                <div class="content-left">
                  <div class="item-info">
                    预期年化收益率：<span
                      >{{ item.annual_profit / 100 }}%+{{
                        item.float_profit / 100
                      }}%</span
                    >
                  </div>
                  <div class="item-info">
                    剩余可入伙金额：<span
                      >{{ item.balance_amount / 100 }}元</span
                    >
                  </div>
                  <div class="item-info">入伙最大风险：<span>0%</span></div>
                </div>
                <div class="content-right">
                  <van-circle
                    v-model="item.planned_speed / 100"
                    :rate="100"
                    layer-color="#C8DFFF"
                    :clockwise="false"
                    :stroke-width="80"
                    :size="60"
                    :speed="100"
                    :text="(item.planned_speed / 100).toFixed(1) + '%'"
                  />
                  <h5>募集进度</h5>
                </div>
              </div>
              <div class="item-footer">
                <div class="footer-left">
                  <span>募集期截止：</span>
                  <van-count-down
                    :time="item.raise_end * 1000 - date"
                    format="DD天HH时mm分钟"
                  />
                </div>
                <div class="footer-right">
                  <button
                    type="button"
                    @click="
                      $router.push({
                        path: '/collection',
                        query: { id: item.id },
                      })
                    "
                  >
                    立即入伙
                  </button>
                </div>
              </div>
            </div>
            <router-link to="/joinRaise" class="more">
              <h5>更多产品</h5>
              <img src="../assets/img/more.png" alt="" />
            </router-link>
          </div>
        </div>
        <!-- 定制 -->
        <div class="bespoke">
          <div class="join-title">
            <div class="line"></div>
            <div class="text">
              <span>VIP定制</span>
              合伙周期自由设定，我的合伙我做主
            </div>
          </div>
          <div class="bespoke-content">
            <h4>VIP定制</h4>
            <p>合伙周期自由设定，我的合伙我做主</p>
            <button type="button" @click="$router.push('/customized')">
              进行定制
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 安全和合作伙伴 -->
    <div class="partner-section">
      <img src="../assets/img/bg2.png" class="bg2" alt="" />
      <img src="../assets/img/bg3.png" class="bg3" alt="" />
      <div class="content">
        <!-- 安全 -->
        <div class="safe">
          <div class="join-title">
            <div class="line"></div>
            <div class="text">
              <span>安全机制</span>
              安全是投资的生命线，我们一直在努力
            </div>
          </div>
          <div class="safe-banner">
            <el-carousel
              trigger="click"
              height="323px"
              direction="vertical"
              :interval="5000"
            >
              <el-carousel-item v-for="(item, index) in safes" :key="index">
                <a href="javascript:;" style="cursor: auto"
                  ><img :src="net + item.pic" alt=""
                /></a>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
        <!-- 合作伙伴 -->
        <div class="partner">
          <div class="partner-title">
            <h5>合作伙伴</h5>
            <div class="line"></div>
          </div>
          <div class="partner-content">
            <div
              class="partner-item"
              v-for="(item, index) in partner"
              :key="index"
            >
              <a :href="item.link" target="_blank"
                ><img :src="net + item.pic" alt=""
              /></a>
            </div>
          </div>
          <div class="tip">
            <p>
              风险提示:<br />
              投资者应仔细阅读合伙产品的相关协议，了解产品风险和收益特征(包括但不限于系统性风险和特定产品所特有的投资风险等)。<br />
              投资者应根据自身资产状况、风险承受能力选择适合自己的合伙产品。<br />
              汇成国际提供的宣传推介材料仅供投资者参考，不构成汇成国际的任何推荐或投资建议，投资者应审慎决策、独立承担风险。
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="footer">
      <div class="footer-contact">
        <div class="content">
          <div class="footer-logo">
            <img src="../assets/img/logo-footer.png" alt="" />
          </div>
          <div class="contact-us">
            <h4>联系我们</h4>
            <p>客服电话：{{ service.service_phone }}</p>
            <p>客服邮箱：{{ service.email }}</p>
          </div>
          <div class="honours">
            <h4>企业荣誉</h4>
            <div class="honour-img">
              <a
                :href="item.link"
                target="_blank"
                v-for="(item, index) in honor"
                :key="index"
              >
                <img :src="net + item.pic" alt="" />
              </a>
            </div>
          </div>
          <div class="download">
            <h4>下载</h4>
            <div class="download-qr">
              <div class="qr-item">
                <img :src="net + service.app_download" alt="" />
                <h5>APP下载</h5>
              </div>
              <div class="qr-item">
                <img :src="net + service.wechat" alt="" />
                <h5>官方微信</h5>
              </div>
            </div>
          </div>
        </div>
        <div class="link">
          <span>友情链接:</span>
          <div class="links">
            <a
              :href="item.link"
              target="_blank"
              v-for="(item, index) in links"
              :key="index"
              >{{ item.title }}</a
            >
          </div>
        </div>
        <div class="copyright">
          ©copyright 2021-永远 汇成国际 hcgj.co 版权所有
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank"
            >辽ICP备2021009245号-2</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getAdmissionDetail } from "@network/admissionDetail";
import { BASE_URL } from "@constants/constants";
import {
  getHonor,
  getHome,
  getPartner,
  getNotice,
  getLinks,
} from "@network/home";
export default {
  name: "Home",
  data() {
    return {
      setting: {
        title: "",
        keywords: "",
        description: "",
      },
      date: new Date(),
      net: BASE_URL,
      time: 30 * 60 * 60 * 1000,
      currentRate: 36.5,
      downloadData: {
        url: window.location.href,
      },
      honor: [],
      banner_imgs: [],
      partner: [],
      notices: [], //公告
      list: [], //入伙
      service: {}, //服务
      links: [], //友情链接
      safes: [], //安全机制
    };
  },

  created() {
    this.getHome();
    this.getHonor();
    this.getPartner();
    this.getNotice();
    this.invest();
    this.getLinks();
  },

  metaInfo() {
    return {
      title: this.setting.title,
      meta: [
        { name: "keywords", content: this.setting.keywords },
        { name: "description", content: this.setting.description },
      ],
    };
  },

  components: {},

  methods: {
    //获取
    invest() {
      getAdmissionDetail("1", "3", "6", "desc", "", "", "", "", "").then(
        (res) => {
          console.log(res);
          if (res.code != 0) {
            // this.$message.error({
            //   offset: 300,
            //   message: "获取失败",
            // });
            return false;
          }
          this.list = res.data;
        }
      );
    },
    //公告
    getNotice() {
      getNotice(1, 2).then((res) => {
        console.log(res);
        if (res.code != 0) {
          return false;
        }
        this.notices = res.data;
      });
    },
    //友情链接
    getLinks() {
      getLinks().then((res) => {
        console.log(res);
        if (res.code != 0) {
          return false;
        }
        this.links = res.data;
      });
    },
    bannerTo(type) {
      if (type == 0) {
        return false;
      } else if (type == 1) {
        this.$router.push("/joinRaise");
      } else if (type == 2) {
        this.$router.push("/customized");
      }
    },
    //企业荣誉
    getHonor() {
      getHonor().then((res) => {
        console.log(res);
        if (res.code != 0) {
          return false;
        } else {
          this.honor = res.data;
        }
      });
    },
    getHome() {
      getHome().then((res) => {
        console.log(res);
        if (res.code != 0) {
          if (res.code == 403) {
            this.$storage.remove("token");
            this.$message.error({
              message: "请重新登录",
              offset: 300,
            });
            this.$router.replace("/home");
            return false;
          }
          this.$message.error({
            message: "获取失败",
            offset: 300,
          });
          return false;
        } else {
          this.banner_imgs = res.data.carousel;
          this.service = res.data.homePage;
          this.safes = res.data.carouselSafe;
          this.setting.title = res.data.homePage.website_name;
          this.setting.keywords = res.data.homePage.website_keywords;
          this.setting.description = res.data.homePage.website_desc;
        }
      });
    },
    getPartner() {
      getPartner().then((res) => {
        console.log(res);
        if (res.code != 0) {
          return false;
        } else {
          this.partner = res.data;
        }
      });
    },
  },

  computed: {},
};
</script>
<style lang='less' scoped>
@import "../assets/css/homeLogged.less";
.join-prods,
.partner {
  position: relative;
  z-index: 5;
}
.bg1 {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 58px;
  width: 182px;
}
.bg2 {
  z-index: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
}
.bg3 {
  width: 93px;
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 172px;
  width: 182px;
}
</style>